import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Person from '../views/Person.vue'
import AddPerson from '../views/AddPerson.vue'
import Summary from '../views/Summary.vue'
import Recommendation from '../views/Recommendation.vue'

import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'

import Profile from '../views/profile/Index.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      guest: true
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/person',
    name: 'Person',
    component: Person,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-person',
    name: 'AddPerson',
    component: AddPerson,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/summary',
    name: 'Summary',
    component: Summary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/recommendation/:id',
    name: 'Recommendation',
    component: Recommendation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      guest: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      guest: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token')) {
      next()
      return
    }
    next({
      path: '/login',
    })
  } 

  if (to.matched.some(record => record.meta.guest)){
    if(!localStorage.getItem('token')){
      next()
      return
    }
    next({
      path: '/home',
    })
  }
})

export default router
