<template>
  <v-container>
    <div class="page-auth">
      <div class="d-flex logo">
        <v-spacer></v-spacer>
          <a href="https://fut.shoes/">
            <v-img 
              width="90" 
              :src="require('@/assets/logo.png')">
            </v-img>
          </a>
        <v-spacer></v-spacer>
      </div>


      <h1 class="text-h5 font-weight-bold my-5 mb-7">Sign in to your Fut Account</h1>


      <v-alert
        v-if="errMsg"
        dense
        outlined
        type="error">
        <!-- The provided credentials are incorrect. -->
        {{ errMsg }}
      </v-alert>
      <v-text-field
          v-model="email"
          label="Email"
          outlined
          name="email"
          data-vv-as="Email"
          v-validate="'required|email|max:50'"
          :error-messages="errors.collect('email')"
          :hide-details="errors.collect('email').length > 0 ? false:true">
      </v-text-field>

      <v-text-field
          class="mt-6"
          v-model="password"
          label="Password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          outlined
          name="password"
          data-vv-as="Password"
          v-validate="'required'"
          :error-messages="errors.collect('password')"
          :hide-details="errors.collect('password').length > 0 ? false:true">
      </v-text-field>

      <div class="d-flex">
        <router-link to="/forgot-password" class="mt-6 d-block">
          Forgot password?
        </router-link>
        <v-spacer></v-spacer>
        <router-link to="/register" class="mt-6 d-block">
          Create an account
        </router-link> 
      </div>

      <div>
        <v-btn 
          v-if="!loading"
          class="mt-5" 
          rounded 
          color="blue" 
          dark 
          depressed 
          block
          x-large
          @click="Login"
          style="text-transform: capitalize;">
          <v-spacer></v-spacer>
            Login
          <v-spacer></v-spacer>
        </v-btn>
        <v-btn 
          v-else
          class="mt-5" 
          rounded 
          color="blue" 
          dark 
          depressed 
          block
          x-large>
          <v-spacer></v-spacer>
            <v-progress-circular
              :width="3"
              color="white"
              indeterminate>
            </v-progress-circular>
          <v-spacer></v-spacer>
        </v-btn>
      </div>
      

      <!-- <div class="my-5 text-center">
          or
      </div>

      <v-btn 
        rounded 
        outlined
        depressed 
        block
        x-large>
        <v-icon>mdi-google</v-icon>
        <v-spacer></v-spacer>
          <GoogleLogin 
            :params="params" 
            :onSuccess="onSuccess" 
            :onFailure="onFailure">
              Continue With Google
          </GoogleLogin>
        <v-spacer></v-spacer>
      </v-btn> -->
      
      <!-- <v-btn 
        class="mt-5" 
        rounded 
        color="blue" 
        dark 
        depressed 
        block
        x-large
        @click="logInWithFacebook"
        style="text-transform: capitalize;">
        <v-icon>mdi-facebook</v-icon>
        <v-spacer></v-spacer>
          Continue With Facebook
        <v-spacer></v-spacer>
      </v-btn> -->
    </div>
  </v-container>
</template>

<script>
  import GoogleLogin from 'vue-google-login';
  export default {
    name: 'Login',

    components: {
      GoogleLogin
    },

    data() {
      return {
        email: '',
        password: '',
        showPassword: false,
        errMsg: '',
        loading: false,

        params: {
            client_id: "523504573002-kjjunpggm1g7laq6ssbk8pmps4sh3m0s.apps.googleusercontent.com"
        },
        // only needed if you want to render the button with the google ui
        renderParams: {
            width: 250,
            height: 50,
            longtitle: true
        }
      }
    },

    // async mounted() {
    //     await this.loadFacebookSDK(document, "script", "facebook-jssdk")
    //     await this.initFacebook()
    // },

    methods: {
        Login(){
          this.$validator.validateAll().then(result => {
            if (result) {
              const app = this
              app.loading = true
              axios.post('/login', {
                  email: this.email,
                  password: this.password
                })
                .then(response => {
                    app.loading = false
                    localStorage.setItem('token', response.data.token)
                    app.$router.push({ path: '/' })
                }, (err) => {
                    app.loading = false
                    app.errMsg = err.response.data.errors.email[0]
                    // router.push({ path: '/login' })
                });
            }
          })
        },

        onSuccess(googleUser) {
            // This only gets the user information: id, name, imageUrl and email
            const data = {
              provider: 'google',
              token: googleUser.getAuthResponse().id_token
            }

            this.$store.dispatch('auth/loginSocial', data)
        },

        onFailure(data){
          console.log(data)
        },


        // async logInWithFacebook() {
        //     try{
        //         const app = this
        //         window.FB.login(function(response) {
        //             if (response.authResponse) {
        //                 const tokenFB = response.authResponse.accessToken
        //                 const data = {
        //                   provider: 'facebook',
        //                   token: tokenFB
        //                 }
        //                 console.log(data)
        //                 this.$store.dispatch('auth/loginSocial', data)
        //             } else {
        //                 alert("User cancelled login or did not fully authorize.");
        //             }
        //         });
        //         return false;
        //     } catch(e){
        //         console.log(e)
        //     }
        // },
        // async initFacebook() {
        //     window.fbAsyncInit = function() {
        //         window.FB.init({
        //             appId: "668883654078860", //You will need to change this
        //             cookie: true, // This is important, it's not enabled by default
        //             version: "v13.0"
        //         });
        //     };
        // },
        // async loadFacebookSDK(d, s, id) {
        //     var js,
        //         fjs = d.getElementsByTagName(s)[0];
        //     if (d.getElementById(id)) {
        //         return;
        //     }
        //     js = d.createElement(s);
        //     js.id = id;
        //     js.src = "https://connect.facebook.net/en_US/sdk.js";
        //     fjs.parentNode.insertBefore(js, fjs);
        // }
    },
    
    computed: {
      //
    }
  }
</script>
