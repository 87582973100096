import router from '../../router'

export default {
    state: {
        step: 1,

        dataPersonal: '',
        dataHeightWeight: '',
        dataMeasurement: '',
        dataActivity: '',

        persons: [],
        person: '',

        recommendation: '',

        loading: false,

        sendMeasurement: {
            dialog: false,
            id: ''
        }
    },
    getters: {},
    mutations: {
        setStep(state, payload) {
            state.step = payload
        },
        setDataPersonal(state, payload) {
            state.dataPersonal = payload
        },
        setDataHeightWeight(state, payload) {
            state.dataHeightWeight = payload
        },
        setDataMeasurement(state, payload) {
            state.dataMeasurement = payload
        },
        setDataActivity(state, payload) {
            state.dataActivity = payload
        },
        
        setLoading(state, payload) {
            state.loading = payload
        },
        setPerson(state, payload) {
            state.persons = payload
        },
        setShowPerson(state, payload) {
            state.person = payload
        },
        
        setRecommendation(state, payload) {
            state.recommendation = payload
        },
        
        setSendMeasurement(state, payload) {
            state.sendMeasurement = payload
        },
    },

    actions: {
        step({commit}, payload){
            commit('setStep', payload)
        },

        dataPersonal({commit}, payload){
            commit('setDataPersonal', payload)
        },
        dataHeightWeight({commit}, payload){
            commit('setDataHeightWeight', payload)
        },
        dataMeasurement({commit}, payload){
            commit('setDataMeasurement', payload)
        },
        dataActivity({commit}, payload){
            commit('setDataActivity', payload)
        },

        person({ commit }, payload){
            axios.get('/measurement', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                commit('setLoading', false)
                commit('setPerson', response.data)
            }, (err) => {
                //
            });
        },

        save({ commit }, payload){
            if(payload.id){
                axios.put('/measurement/' + payload.id, payload, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then(response => {
                    axios.get('/measurement/recommendation/' + payload.id, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    })
                    .then(resp => {
                        if (resp.data.status == 200){
                            commit('setLoading', false)
                            commit('setRecommendation', resp.data)
                            commit('setSendMeasurement', {
                                dialog: true,
                                id: payload.id
                            })
                        }
                        router.push({ path: '/recommendation/' + payload.id })
                    }, (err) => {
                        //
                    });
                }, (err) => {
                    //
                });
            } else {
                axios.post('/measurement', payload, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then(response => {
                    axios.get('/measurement/recommendation/' + response.data.id, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        }
                    })
                    .then(resp => {
                        commit('setLoading', false)
                        commit('setRecommendation', resp.data)
                        if (resp.data.status == 200) {
                            commit('setSendMeasurement', {
                                dialog: true,
                                id: response.data.id
                            })
                            router.push({ path: '/recommendation/' + response.data.id })
                        }
                    }, (err) => {
                        //
                    });
                }, (err) => {
                    //
                });
            }
        },
        
        show({ commit }, payload){
            axios.get('/measurement/' + payload.id, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                commit('setLoading', false)
                commit('setShowPerson', response.data)
            }, (err) => {
                //
            });
        },
        
        delete({ commit }, payload){
            axios.delete('/measurement/' + payload.id, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                commit('setLoading', false)
                axios.get('/measurement', {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then(response => {
                    commit('setLoading', false)
                    commit('setPerson', response.data)
                }, (err) => {
                    //
                });

            }, (err) => {
                //
            });
        },

        recommendation({ commit }, payload) {
            commit('setLoading', true)
            axios.get('/measurement/recommendation/' + payload.id, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                commit('setLoading', false)
                commit('setRecommendation', response.data)
                router.push({ path: '/recommendation/' + payload.id })
            }, (err) => {
                //
            });
        },

        closeSendMeasurement({commit}, payload){
            commit('setSendMeasurement', {
                dialog: false,
                id: ''
            })
        }
    }
}
