<template>
  <div v-if="globalData.downloadFile">
    <v-dialog
      v-if="globalData.downloadFile.sendMeasurement"
      v-model="data.dialog"
      persistent
      max-width="350">
      <v-card>
        <v-card-title class="text-h6 mb-4">
          Send your foot measurement
        </v-card-title>
        <v-card-text class="text-center">
          <div v-if="!loading">
            <v-btn
              v-if="dataPersonal.email && globalData.downloadFile.sendEmail" 
              outlined 
              @click="send('email')"
              class="mr-3">
              <v-icon>mdi-email</v-icon>
              Email
            </v-btn>
            <v-btn 
              v-if="dataPersonal.phone && globalData.downloadFile.sendWA"
              outlined 
              @click="send('wa')">
              <v-icon>mdi-whatsapp</v-icon>
              Whatsapp
            </v-btn>
          </div>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary">
          </v-progress-circular>
        </v-card-text>
        <!-- <v-card-actions>
          <v-btn
            color="red darken-1"
            block
            text
            @click="cancel">
            Cancel
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'SendMeasurement',
    props: [
      //
    ],
    data: () => ({
        loading: false
    }),

    methods: {
      send(send){
        const app = this
        this.loading = true;
        axios.post('/send', {
          id: this.data.id,
          send: send
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(response => {
            this.$store.dispatch('person/closeSendMeasurement')
        }, (err) => {
            this.$store.dispatch('person/closeSendMeasurement')
        });
      },

      cancel(){
        this.$store.dispatch('person/closeSendMeasurement')
      }
    },

    computed: {
        data(){
          return this.$store.state.person.sendMeasurement
        },
        dataPersonal(){
          return this.$store.state.person.dataPersonal
        },
    }
  }
</script>
