<template>
  <v-container>
    <div class="page-auth">
      <div class="d-flex logo">
        <v-spacer></v-spacer>
          <a href="https://fut.shoes/">
            <v-img 
              width="90" 
              :src="require('@/assets/logo.png')">
            </v-img>
          </a>
        <v-spacer></v-spacer>
      </div>

      <h1 class="text-h5 font-weight-bold my-5 mb-7">Create Fut Account</h1>

      <v-alert
        v-if="errMsg"
        dense
        outlined
        type="error">
        <!-- The provided credentials are incorrect. -->
        {{ errMsg }}
      </v-alert>
      <v-text-field
          v-model="name"
          label="Full Name"
          outlined
          name="Full Name"
          data-vv-as="Full Name"
          v-validate="'required|max:50'"
          :error-messages="errors.collect('name')"
          :hide-details="errors.collect('name').length > 0 ? false:true">
      </v-text-field>

      <v-text-field
          class="mt-6"
          v-model="email"
          label="Email"
          outlined
          name="email"
          data-vv-as="Email"
          v-validate="'required|email|max:50'"
          :error-messages="errors.collect('email')"
          :hide-details="errors.collect('email').length > 0 ? false:true">
      </v-text-field>

      <v-text-field
          class="mt-6"
          v-model="password"
          label="Password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          outlined
          name="password"
          data-vv-as="Password"
          v-validate="'required|min:7|max:50'"
          :error-messages="errors.collect('password')"
          :hide-details="errors.collect('password').length > 0 ? false:true">
      </v-text-field>

      <v-checkbox 
        v-model="tnc"
        name="tnc"
        data-vv-as="Term and Conditions"
        v-validate="'required'"
        :error-messages="errors.collect('tnc')"
        :hide-details="errors.collect('tnc').length > 0 ? false:true">
        <template v-slot:label>
          <div>
            I agree with our 
            <a
                target="_blank"
                href="https://fut.shoes/terms-and-condition">
                Terms and Conditions
              </a>
          </div>
        </template>
      </v-checkbox>

      <div class="d-flex">
        <router-link to="/login" class="mt-6 d-block">
          <span style="color: #000;">Already have an account?</span> Login
        </router-link>
        <v-spacer></v-spacer>
      </div>

      <div>
        <v-btn 
          v-if="!loading"
          class="mt-5" 
          rounded 
          color="blue" 
          dark 
          depressed 
          block
          x-large
          @click="Register"
          style="text-transform: capitalize;">
          <v-spacer></v-spacer>
            Create Account
          <v-spacer></v-spacer>
        </v-btn>
        <v-btn 
          v-else
          class="mt-5" 
          rounded 
          color="blue" 
          dark 
          depressed 
          block
          x-large>
          <v-spacer></v-spacer>
            <v-progress-circular
              :width="3"
              color="white"
              indeterminate>
            </v-progress-circular>
          <v-spacer></v-spacer>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Register',

    components: {
      //
    },

    data() {
      return {
        name: '',
        email: '',
        password: '',
        tnc: true,
        showPassword: false,
        errMsg: '',
        loading: false,
       }
    },

    methods: {
        Register(){
          this.$validator.validateAll().then(result => {
            if (result) {
              const app = this
              app.loading = true
              axios.post('/register', {
                  name: this.name,
                  email: this.email,
                  password: this.password,
                  tnc: this.tnc
                })
                .then(response => {
                    app.loading = false
                    localStorage.setItem('token', response.data.token)
                    app.$router.push({ path: '/' })
                }, (err) => {
                    app.loading = false
                    app.errMsg = err.response.data.errors.email[0]
                    // router.push({ path: '/login' })
                });
            }
          })
        },
    },
    
    computed: {
      //
    }
  }
</script>
