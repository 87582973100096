<template>
  <div>
      <v-container>
        <h1 class="text-h5 font-weight-bold">Person</h1>
      </v-container>
      
      <v-list flat>
          <v-list-item-group color="primary">
            <v-divider></v-divider>
            <template v-for="person in persons">
              <v-list-item @click="recomendation(person.id)" :key="person.id">
                  <v-list-item-content>
                    <v-list-item-title>{{ person.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="editPerson(person.id)">
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deletePerson(person.id)">
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <!-- <v-icon>mdi-chevron-right</v-icon> -->
                  </v-list-item-icon>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-list-item-group>
        </v-list>

      <v-container>
        <v-btn 
          class="mt-10" 
          rounded 
          color="blue" 
          dark 
          depressed 
          block
          x-large
          @click="addPerson">
          Add Person
        </v-btn>
      </v-container>

  </div>
</template>

<script>

  export default {
    name: 'Person',

    components: {
      //
    },

    data() {
      return {
        //
      }
    },

    methods: {
      editPerson(id){
        this.$store.dispatch('person/show', {
          id: id
        })

        this.$router.push('/add-person')
      },
      
      deletePerson(id){
         if (confirm('Are you sure you want to delete this item?')) {
            this.$store.dispatch('person/delete', {
              id: id
            })
         }
      },
      
      recomendation(id){
        this.$store.dispatch('person/recommendation', {
          id: id
        })

        this.$router.push('/recommendation')
      },

      addPerson(){
        this.$router.push('/add-person')
        location.reload();
      },
    },

    created() {
      this.$store.dispatch('person/person')
    },

    computed: {
      persons(){
        return this.$store.state.person.persons
      },
    }
  }
</script>
