<template>
  <div>
      <v-container>
        <h1 class="text-h5 font-weight-bold">Add Person</h1>
      </v-container>

      <v-stepper v-model="step" vertical flat>
      <v-stepper-step :complete="step > 1" step="1">
        Personal Information
        <!-- <small>Summarize if needed</small> -->
      </v-stepper-step>
      <v-stepper-content step="1">
          <personal></personal>
      </v-stepper-content>

    <!-- <v-stepper-step :complete="step > 2" step="2">
      Height & Weight
    </v-stepper-step>
    <v-stepper-content step="2">
        <heigth-weight></heigth-weight>
    </v-stepper-content> -->

    <!-- <v-stepper-step :complete="step > 2" step="2">
      Activity
    </v-stepper-step>
    <v-stepper-content step="2">
        <activity></activity>
    </v-stepper-content> -->

    <v-stepper-step step="2">
      Measurements
    </v-stepper-step>
    <v-stepper-content step="2">
      <measurement></measurement>
    </v-stepper-content>
  </v-stepper>

  </div>
</template>

<script>
  import Personal from '../components/person/AddPersonal.vue'
  // import HeigthWeight from '../components/person/AddHeigthWeight.vue'
  import Activity from '../components/person/AddActivity.vue'
  import Measurement from '../components/person/AddMeasurement.vue'

  export default {
    name: 'AddPerson',

    components: {
      Personal,
      // HeigthWeight,
      Activity,
      Measurement
    },

    data() {
      return {
        //
      }
    },

    computed: {
      step(){
        return this.$store.state.person.step
      }
    }
  }
</script>
