<template>
  <div>
    <v-dialog 
        ref="dialog1"
        v-model="modalTime"
        :return-value.sync="wake_up"
        persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              class="mt-1 mb-3"
              v-model="wake_up"
              label="Wake up time"
              dense
              outlined
              v-bind="attrs"
              v-on="on"
              readonly
              name="wake_up"
              data-vv-as="Wake up time"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('wake_up')"
              :hide-details="errors.collect('wake_up').length > 0 ? false:true">
          </v-text-field>
        </template>
        <v-time-picker format="24hr" v-if="modalTime" v-model="wake_up" full-width>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modalTime = false">
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog1.save(wake_up)">
            OK
          </v-btn>
        </v-time-picker>
      </v-dialog>
      <v-select
          class="mb-3"
          label="Activity"
          :items="activities"
          dense
          outlined
          v-model="activity"
          name="activity"
          data-vv-as="Activity"
          v-validate="'required'"
          :error-messages="errors.collect('activity')"
          :hide-details="errors.collect('activity').length > 0 ? false:true">
      </v-select>
      <v-text-field
        v-if="activity != 'Sitting'"
        class="mb-3"
        label="How Many Meter?"
        dense
        outlined
        v-model="activity_meter"
        name="activity_meter"
        data-vv-as="How Many Meter?"
        v-validate="'required|max:50'"
        :error-messages="errors.collect('activity_meter')"
        :hide-details="errors.collect('activity_meter').length > 0 ? false:true">
    </v-text-field>

    <v-btn color="primary" @click="save" class="mr-2">
      Continue
    </v-btn>
    <v-btn text @click="cancel">
      Cancel
    </v-btn>

    {{ getData }}
  </div>
</template>

<script>
  export default {
    name: 'AddActivity',

    data: () => ({
        wake_up: '',
        activity_meter: '',
        activity: '',

        activities: ['Sitting', 'Run', 'Walk'],
        modalTime: false,
    }),

    methods: {
      save(){
        this.$validator.validateAll().then(result => {
            if (result) {

              this.$store.dispatch('person/dataActivity', {
                wake_up: this.wake_up,
                activity_meter: this.activity_meter,
                activity: this.activity,
              })

              this.$store.dispatch('person/step', 3)
            }
        })
      },

      cancel(){
        this.$store.dispatch('person/step', 1)
      }
    },

    computed: {
      person(){
        return this.$store.state.person.person
      },

      getData(){
        const person = this.person
        if(person){
          this.wake_up = this.person.wake_up
          this.activity_meter = this.person.activity_meter
          this.activity = this.person.activity
        } else {
          this.wake_up = ''
          this.activity_meter = ''
          this.activity = ''
        }
        
      }
    }
  }
</script>
