import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import VeeValidate from "vee-validate"
import axios from 'axios'
// import GAuth from 'vue-google-oauth2'
// const gauthOption = {
//   clientId: '523504573002-kjjunpggm1g7laq6ssbk8pmps4sh3m0s.apps.googleusercontent.com',
//   scope: 'profile email',
//   prompt: 'select_account'
// }
// Vue.use(GAuth, gauthOption)

window.axios = axios;
Vue.use(VeeValidate);
Vue.config.productionTip = false

axios.defaults.baseURL = 'https://app.sizefoot.com/api/v1';

Vue.mixin({
  data: function () {
    return {
      globalData: window,
    }
  },
  methods: {
    //
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
