<template>
  <div>
    <v-text-field
        class="mt-1 mb-3"
        label="Name"
        dense
        outlined
        v-model="name"
        name="name"
        data-vv-as="Name"
        v-validate="'required|max:50'"
        :error-messages="errors.collect('name')"
        :hide-details="errors.collect('name').length > 0 ? false:true">
      </v-text-field>
      <v-text-field
        class="mt-1 mb-3"
        label="Email"
        dense
        outlined
        type="email"
        v-model="email"
        name="email"
        data-vv-as="Email"
        v-validate="'required|max:50|email'"
        :error-messages="errors.collect('email')"
        :hide-details="errors.collect('email').length > 0 ? false:true">
      </v-text-field>
      <v-text-field
        class="mt-1 mb-3"
        label="Phone (Optional)"
        placeholder="+628xxxxxxxxxx"
        dense
        outlined
        type="text"
        v-model="phone"
        name="phone"
        data-vv-as="Phone"
        v-validate="''"
        :error-messages="errors.collect('phone')"
        :hide-details="errors.collect('phone').length > 0 ? false:true">
      </v-text-field>
      <v-select
          class="mb-3"
          label="Gender"
          :items="genders"
          dense
          outlined
          v-model="gender"
          name="gender"
          data-vv-as="Gender"
          v-validate="'required|max:20'"
          :error-messages="errors.collect('gender')"
          :hide-details="errors.collect('gender').length > 0 ? false:true">
      </v-select>
      <div class="mb-1"> 
        <label class="grey--text text--darken-1">Date Birth</label>
      </div>
      <v-row> 
        <v-col>
          <v-text-field
              class="mb-3"
              label="Year"
              placeholder="2000"
              dense
              outlined
              type="number"
              v-model="year"
              name="year"
              data-vv-as="year"
              v-validate="'required|date_format:yyyy'"
              :error-messages="errors.collect('year')"
              :hide-details="errors.collect('year').length > 0 ? false:true">
          </v-text-field>
        </v-col>
        <v-col class="px-0">
          <v-text-field
              class="mb-3"
              label="Month"
              placeholder="01"
              dense
              outlined
              type="number"
              v-model="month"
              name="month"
              data-vv-as="month"
              v-validate="'required|date_format:MM'"
              :error-messages="errors.collect('month')"
              :hide-details="errors.collect('month').length > 0 ? false:true">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
              class="mb-3"
              label="Day"
              placeholder="01"
              type="number"
              dense
              outlined
              v-model="day"
              name="day"
              data-vv-as="day"
              v-validate="'required|date_format:dd'"
              :error-messages="errors.collect('day')"
              :hide-details="errors.collect('day').length > 0 ? false:true">
          </v-text-field>
        </v-col>
      </v-row>
      <!-- <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date_birth"
          persistent
          width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mb-3"
              v-model="date_birth"
              label="Date of Birth"
              dense
              outlined
              v-bind="attrs"
              v-on="on"
              readonly
              name="date_birth"
              data-vv-as="Date of Birth"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('date_birth')"
              :hide-details="errors.collect('date_birth').length > 0 ? false:true">
            </v-text-field>
          </template>
          <v-date-picker v-model="date_birth" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date_birth)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog> -->

      <!-- <v-select
          class="mb-3"
          label="Race"
          dense
          outlined
          :items="dataSize.race"
          v-model="race"
          name="race"
          data-vv-as="Race"
          v-validate="'required|max:255'"
          :error-messages="errors.collect('race')"
          :hide-details="errors.collect('race').length > 0 ? false:true">
      </v-select> -->
      <!-- <v-text-field
          class="mb-3"
          label="Current Location"
          dense
          outlined
          v-model="location"
          name="location"
          data-vv-as="Current Location"
          v-validate="'required|max:255'"
          :error-messages="errors.collect('location')"
          :hide-details="errors.collect('location').length > 0 ? false:true">
      </v-text-field> -->
      <v-text-field
          class="mb-3"
          label="Height (CM)"
          dense
          outlined
          type="number"
          v-model="height"
          name="height"
          data-vv-as="Height"
          v-validate="'required|max:1000'"
          :error-messages="errors.collect('height')"
          :hide-details="errors.collect('height').length > 0 ? false:true">
      </v-text-field>
      <!-- <v-text-field
          class="mb-3"
          label="Weight"
          dense
          outlined
          v-model="weight"
          name="weight"
          data-vv-as="Weight"
          v-validate="'required|max:1000'"
          :error-messages="errors.collect('weight')"
          :hide-details="errors.collect('weight').length > 0 ? false:true">
      </v-text-field> -->

    <v-btn color="primary" @click="save" class="mr-2">
      Continue
    </v-btn>
    <v-btn text @click="cancel">
      Cancel
    </v-btn>
    {{ this.date_birth }}
    {{ getData }}
  </div>
</template>

<script>
  export default {
    name: 'AddPersonal',

    data: () => ({
        modal: false,
        genders: ['Male', 'Female'],
        // date_birth: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date_birth: '',

        name: '',
        phone: '',
        email: '',
        gender: '',
        race: '',
        location: '',
        height: '',
        weight: '',

        year: '',
        month: '',
        day: '',
        dataSize: [],
    }),

    watch: {
      year(val) {
        this.year  = val
      },
      month(val) {
        this.month  = val
      },
      day(val) {
        this.day = val
      }
    },

    created () {
      const app = this
      axios.get('/measurement/data-size', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(response => {
            app.dataSize = response.data
        }, (err) => {
            //
        });
    },

    methods: {
      range(start, end){
        const rangeArray = [] 
        for(let i = start; i <= end; i++){
          rangeArray.push(i)
        }
        return rangeArray
      },
      save(){
        this.$validator.validateAll().then(result => {
            if (result) {
              this.$store.dispatch('person/dataPersonal', {
                // date_birth: this.date_birth,
                date_birth: this.year + '-' + this.month + '-' + this.day,
                name: this.name,
                email: this.email,
                gender: this.gender,
                phone: this.phone,
                // race: this.race,
                // location: this.location,
                height: this.height,
                // weight: this.weight,
              })
              
              this.$store.dispatch('person/step', 2)
            }
        })
      },

      cancel(){
        this.$router.push('/person')
      }
    },

    computed: {
      person(){
        return this.$store.state.person.person
      },

      getData(){
        const person = this.person
        if(person){
          this.name = this.person.name
          this.email = this.person.email
          this.gender = this.person.gender
          this.phone = this.person.phone
          // this.race = this.person.race
          // this.location = this.person.location
          this.height = this.person.height
          // this.weight = this.person.weight

          if(this.person.date_birth){
            const date = this.person.date_birth.split('-')
            this.year = parseInt(date[0])
            this.month = date[1]
            this.day = date[2]
          } else {
            this.year = ''
            this.month = ''
            this.day = ''
          }
        } else {
          this.name = ''
          this.email = ''
          this.gender = ''
          this.phone = ''
          // this.race = ''
          // this.location = ''
          this.height = ''
          // this.weight = ''
          this.year = ''
          this.month = ''
          this.day = ''
        }
        
      }
    }
  }
</script>
