import router from '../../router'

export default {
    state: {
        user: '',

        loading: false
    },
    getters: {},
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
    },

    actions: {
        user({ commit }, payload){
            axios.get('/me', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                commit('setLoading', false)
                commit('setUser', response.data)
            }, (err) => {
                //
            });
        },

        loginSocial({ commit }, payload){
            commit('setLoading', true)
            axios.post('/login-social', payload)
                .then(response => {
                    localStorage.setItem('token', response.data.token)
                    axios.get('/me', {
                        headers: {
                            Authorization: 'Bearer ' + response.data.token
                        }
                    })
                    .then(response => {
                        commit('setLoading', false)
                        commit('setUser', response.data)
                        router.push({ path: '/' })
                    }, (err) => {
                        //
                    });
                }, (err) => {
                    //
                });
        },

        logout({ commit }, payload) {
            axios.get('/logout', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                localStorage.removeItem('token')
                router.push({ path: '/login' })
            }, (err) => {
                //
            });
        },
    }
}
