<template>
  <div>
      <v-container>
        <h1 class="text-h5 font-weight-bold">Recommendations</h1>
      </v-container>
      <div v-if="!loading"> 
        <div v-if="recommendation.status == '200'"> 
          <!-- <v-container>
            <v-row class="scroll-product-mobile">
              <v-col cols="10" v-for="product in recommendation.data" :key="product.id">
                <v-card 
                  :href="product.link"
                  target="_blank"
                  class="elevation-0 mx-auto grey lighten-3 pa-5"
                  rounded="xl">
                  <div class="mb-5" v-if="product.image">
                      <v-img :src="product.image">
                      </v-img>
                    </div>
                    <div>
                      <h4>{{ product.title }}</h4>
                    </div>
                    <div style="line-height: 15px;">
                      <small>
                        Size: {{ product.size }} <br>
                        Width: {{ product.width }}
                      </small> 
                    </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container> -->

          <v-container>
            <div class="d-flex mb-2">
              <h3>Results</h3>
              <v-spacer></v-spacer>
              <h3>Width: {{ recommendation.width }}</h3>
            </div>
            <v-row>
              <v-col cols="3" v-for="size in recommendation.size" :key="size">
                <v-text-field
                  :value="size.split('|')[1]"
                  :label="size.split('|')[0]"
                  dense
                  outlined
                  readonly>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-else>
          <v-container>
            <v-alert
              :value="true"
              type="warning"
              color="blue"
              outlined
              class="ma-0">
              {{ recommendation.message }}
            </v-alert> 
          </v-container>
        </div>
      </div>
      
      <div v-else> 
        <v-container>
          <v-progress-linear
            indeterminate
            color="blue"
            class="mb-5">
          </v-progress-linear>
        </v-container>
      </div>

    <div v-if="!loading"> 
      <div v-if="recommendation.status == '200'"> 
        <v-container>
          <v-row>
            <v-col 
              cols="12" 
              sm="6" 
              v-for="product in recommendation.data" 
              :key="product.id">
              <v-card 
                :href="product.link"
                target="_blank"
                class="elevation-2"
                rounded="xl">
                  <v-img 
                    v-if="product.image" 
                    :src="product.image"
                    :lazy-src="product.image"
                    contain>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-card-text>
                    <div>
                        <h4 class="">{{ product.title }}</h4>
                      </div>
                      <!-- <div style="line-height: 15px;">
                        <small>
                          Size: {{ product.size }} <br>
                          Width: {{ product.width }}
                        </small> 
                      </div> -->
                      <div v-if="product.coupon" style="line-height: 15px;">
                        <small>
                          Coupon: {{ product.coupon }}
                        </small> 
                      </div>
                  </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <send-measurement>
    </send-measurement>
  </div>
</template>

<script>
  import SendMeasurement from '../components/SendMeasurement.vue'
  export default {
    name: 'Recommedation',

    components: {
      SendMeasurement
    },

    data() {
      return {
          dialogSend: false
      }
    },

    created() {
      this.$store.dispatch('person/recommendation', {
          id: this.$route.params.id
      })
    },

    computed: {
      recommendation(){
        return this.$store.state.person.recommendation
      },
      loading(){
        return this.$store.state.person.loading
      },
    }
  }
</script>

<style lang="scss" scoped>
  .scroll-product-mobile{
      flex-flow: row!important;
      overflow-x: scroll!important;
  }
  .v-image {
      border-radius: 24px;
  }
</style>