<template>
  <div>
    <v-row>
        <v-col cols="6" class="pb-0">
          <h4 class="mb-3">Left</h4>
          <v-text-field
              class="mb-3"
              label="Length(mm)"
              dense
              outlined
              type="number"
              v-model="l_foot_length"
              name="l_foot_length"
              data-vv-as="Length"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('l_foot_length')"
              :hide-details="errors.collect('l_foot_length').length > 0 ? false:true">
          </v-text-field>
          <v-text-field
              class="mb-3"
              label="Ball Girth Length(mm)"
              dense
              outlined
              type="number"
              v-model="l_ball_girth_length"
              name="l_ball_girth_length"
              data-vv-as="Ball Girth Length"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('l_ball_girth_length')"
              :hide-details="errors.collect('l_ball_girth_length').length > 0 ? false:true">
          </v-text-field>
          <v-text-field
              class="mb-3"
              label="Width(mm)"
              dense
              outlined
              type="number"
              v-model="l_foot_width"
              name="l_foot_width"
              data-vv-as="Width"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('l_foot_width')"
              :hide-details="errors.collect('l_foot_width').length > 0 ? false:true">
          </v-text-field>
          <v-text-field
              class="mb-3"
              label="Width Thickness(mm)"
              dense
              outlined
              type="number"
              v-model="l_foot_thickness"
              name="l_foot_thickness"
              data-vv-as="Width Thickness"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('l_foot_thickness')"
              :hide-details="errors.collect('l_foot_thickness').length > 0 ? false:true">
          </v-text-field>
          <v-text-field
              class="mb-3"
              label="Instep Thickness(mm)"
              dense
              outlined
              type="number"
              v-model="l_instep_thickness"
              name="l_instep_thickness"
              data-vv-as="Instep Thickness"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('l_instep_thickness')"
              :hide-details="errors.collect('l_instep_thickness').length > 0 ? false:true">
          </v-text-field>
        </v-col>
        <v-col cols="6" class="pb-0">
          <h4 class="mb-3">Right</h4>
          <v-text-field
              class="mb-3"
              label="Length(mm)"
              dense
              outlined
              type="number"
              v-model="r_foot_length"
              name="r_foot_length"
              data-vv-as="Length"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('r_foot_length')"
              :hide-details="errors.collect('r_foot_length').length > 0 ? false:true">
          </v-text-field>
          <v-text-field
              class="mb-3"
              label="Ball Girth Length(mm)"
              dense
              outlined
              type="number"
              v-model="r_ball_girth_length"
              name="r_ball_girth_length"
              data-vv-as="Ball Girth Length"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('r_ball_girth_length')"
              :hide-details="errors.collect('r_ball_girth_length').length > 0 ? false:true">
          </v-text-field>
          <v-text-field
              class="mb-3"
              label="Width(mm)"
              dense
              outlined
              type="number"
              v-model="r_foot_width"
              name="r_foot_width"
              data-vv-as="Width"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('r_foot_width')"
              :hide-details="errors.collect('r_foot_width').length > 0 ? false:true">
          </v-text-field>
          <v-text-field
              class="mb-3"
              label="Width Thickness(mm)"
              dense
              outlined
              type="number"
              v-model="r_foot_thickness"
              name="r_foot_thickness"
              data-vv-as="Width Thickness"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('r_foot_thickness')"
              :hide-details="errors.collect('r_foot_thickness').length > 0 ? false:true">
          </v-text-field>
          <v-text-field
              class="mb-3"
              label="Instep Thickness(mm)"
              dense
              outlined
              type="number"
              v-model="r_instep_thickness"
              name="r_instep_thickness"
              data-vv-as="Instep Thickness"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('r_instep_thickness')"
              :hide-details="errors.collect('r_instep_thickness').length > 0 ? false:true">
          </v-text-field>
        </v-col>
        <!-- <v-col cols="12" class="pt-0">
          <v-select
              class="mb-3"
              label="Socks Type"
              :items="dataSize.socks"
              dense
              outlined
              v-model="socks_type"
              name="socks_type"
              data-vv-as="Socks Type"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('socks_type')"
              :hide-details="errors.collect('socks_type').length > 0 ? false:true">
          </v-select>
        </v-col> -->
      </v-row>

      {{ getData }}

    <v-btn color="primary" @click="save" class="mr-2 mt-5">
      Continue
    </v-btn>
    <v-btn text @click="cancel" class="mt-5">
      Cancel
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'AddMeasurement',

    data: () => ({
        l_foot_length: '',
        l_ball_girth_length: '',
        l_foot_width: '',
        l_foot_thickness: '',
        l_instep_thickness: '',

        r_foot_length: '',
        r_ball_girth_length: '',
        r_foot_width: '',
        r_foot_thickness: '',
        r_instep_thickness: '',

        socks_type: '',

        id: '',

        dataSize: [],
    }),

    created () {
      const app = this
      axios.get('/measurement/data-size', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(response => {
            app.dataSize = response.data
        }, (err) => {
            //
        });
    },

    methods: {
      save(){
        this.$validator.validateAll().then(result => {
            if (result) {

              this.$store.dispatch('person/dataMeasurement', {
                  l_foot_length: this.l_foot_length,
                  l_ball_girth_length: this.l_ball_girth_length,
                  l_foot_width: this.l_foot_width,
                  l_foot_thickness: this.l_foot_thickness,
                  l_instep_thickness: this.l_instep_thickness,

                  r_foot_length: this.r_foot_length,
                  r_ball_girth_length: this.r_ball_girth_length,
                  r_foot_width: this.r_foot_width,
                  r_foot_thickness: this.r_foot_thickness,
                  r_instep_thickness: this.r_instep_thickness,

                  // socks_type: this.socks_type,

                  id: this.id
              })

              this.$router.push('/Summary')
            }
        })
      },

      cancel(){
        this.$store.dispatch('person/step', 1)
      }
    },

    computed: {
      person(){
        return this.$store.state.person.person
      },

      getData(){
        const person = this.person
        if(person){
          this.l_foot_length = this.person.l_foot_length
          this.l_ball_girth_length = this.person.l_ball_girth_length
          this.l_foot_width = this.person.l_foot_width
          this.l_foot_thickness = this.person.l_foot_thickness
          this.l_instep_thickness = this.person.l_instep_thickness

          this.r_foot_length = this.person.r_foot_length
          this.r_ball_girth_length = this.person.r_ball_girth_length
          this.r_foot_width = this.person.r_foot_width
          this.r_foot_thickness = this.person.r_foot_thickness
          this.r_instep_thickness = this.person.r_instep_thickness

          // this.socks_type = this.person.socks_type

          this.id = this.person.id
        } else {
          this.l_foot_length = ''
          this.l_ball_girth_length = ''
          this.l_foot_width = ''
          this.l_foot_thickness = ''
          this.l_instep_thickness = ''

          this.r_foot_length = ''
          this.r_ball_girth_length = ''
          this.r_foot_width = ''
          this.r_foot_thickness = ''
          this.r_instep_thickness = ''

          // this.socks_type = ''

          this.id = ''
        }
        
      }
    }
  }
</script>
