<template>
  <v-app>
    <v-app-bar 
      dense 
      flat 
      fixed 
      color="white" 
      v-if="this.$route.name != 'Login' && this.$route.name != 'Register' && this.$route.name != 'ForgotPassword'">
      <div>
        <v-toolbar-title v-if="this.$route.name == 'Home'">
          <a href="https://fut.shoes/">
            <v-img 
              width="90" 
              :src="require('@/assets/logo.png')">
            </v-img>
          </a>
        </v-toolbar-title>

        <v-btn 
          plain 
          :ripple="false" 
          icon 
          to="/" 
          v-if="this.$route.name == 'Profile'" 
          class="ml-1 text-capitalize text-black black--text">
          <v-icon>mdi-chevron-left</v-icon> {{ this.$route.name }}
        </v-btn>
        <v-btn 
          plain 
          :ripple="false" 
          icon to="/" 
          v-if="this.$route.name == 'Person'" 
          class="ml-1 text-capitalize text-black black--text">
          <v-icon>mdi-chevron-left</v-icon> {{ this.$route.name }}
        </v-btn>
        <v-btn 
          plain 
          :ripple="false" 
          icon 
          to="/person" 
          v-if="this.$route.name == 'AddPerson'" 
          class="ml-5 text-capitalize black--text">
          <v-icon>mdi-chevron-left</v-icon> Add Person
        </v-btn>
        <v-btn 
          plain 
          :ripple="false" 
          icon to="/person" 
          v-if="this.$route.name == 'Summary'" 
          class="ml-3 text-capitalize black--text">
          <v-icon>mdi-chevron-left</v-icon> {{ this.$route.name }}
        </v-btn>
        <v-btn 
          plain 
          :ripple="false" 
          icon 
          to="/person" 
          v-if="this.$route.name == 'Recommendation'" 
          class="ml-10 text-capitalize black--text">
          <v-icon>mdi-chevron-left</v-icon> {{ this.$route.name }}
        </v-btn>
      </div>
      
      <v-spacer></v-spacer>

      <div v-if="globalData.downloadFile">
          <v-bottom-sheet
            v-if="globalData.downloadFile.status" 
            v-model="sheet"
            max-width="500">
            <template 
              v-slot:activator="{ on, attrs }">
              <v-btn 
                dense 
                small 
                outlined
                rounded
                v-bind="attrs"
                v-on="on">
                <v-icon v-if="globalData.downloadFile.icon">
                  {{ globalData.downloadFile.icon }}
                </v-icon>
                {{ globalData.downloadFile.labelbtn }}
              </v-btn>
            </template>
            <v-sheet>
                <div class="text-center" @click="sheet = false">
                  <v-icon>mdi-minus-thick</v-icon>
                </div>
                <v-list>
                  <v-subheader>
                    {{ globalData.downloadFile.labelbtn1 }}
                  </v-subheader>
                  <v-list-item
                    v-for="(file, f) in globalData.downloadFile.items"
                    :key="'file' + f" 
                    :href="file.link"
                    target="_blank"
                    @click="sheet = false">
                    <v-list-item-title>
                      {{ file.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
            </v-sheet>
          </v-bottom-sheet>
      </div>
      
      <v-btn 
        plain 
        :ripple="false" 
        icon 
        v-if="this.$route.name != 'Login'"
        to="/profile">
        <v-avatar size="30" v-if="user.user">
          <img :src="user.user.image" :alt="user.user.name" v-if="user.user.image">
          <v-icon v-else>mdi-account-circle-outline</v-icon>
        </v-avatar>
      </v-btn>
    </v-app-bar> 

    <v-main class="mt-12">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
      sheet: false,
  }),

  created () {
     this.$store.dispatch('auth/user')
  },

  computed: {
    user(){
      return this.$store.state.auth.user
    },
  }
};
</script>

<style lang="scss">
  .v-carousel__controls__item{
    &.theme--dark.v-btn.v-btn--icon {
        color: #9b9b9b!important;
    }
  }
</style>
