<template>
  <v-container>
    <div class="page-auth">
      <div class="d-flex logo">
        <v-spacer></v-spacer>
          <a href="https://fut.shoes/">
            <v-img 
              width="90" 
              :src="require('@/assets/logo.png')">
            </v-img>
          </a>
        <v-spacer></v-spacer>
      </div>


      <h1 class="text-h5 font-weight-bold my-5 mb-7">Reset password</h1>


      <v-alert
        v-if="errMsg"
        dense
        outlined
        type="error">
        <!-- The provided credentials are incorrect. -->
        {{ errMsg }}
      </v-alert>
      <v-alert
        v-if="sucessMsg"
        dense
        outlined
        type="success">
        {{ sucessMsg }}
      </v-alert>

      <div> 
        <div v-if="!forgotPassword"> 
          <v-text-field
              class="mt-6"
              v-model="email"
              label="Email"
              outlined
              name="email"
              data-vv-as="Email"
              v-validate="'required|email|max:50'"
              :error-messages="errors.collect('email')"
              :hide-details="errors.collect('email').length > 0 ? false:true">
          </v-text-field>
        </div>
        <div v-else> 
          <v-text-field
              class="mt-6"
              v-model="code"
              label="Code"
              outlined
              name="Code"
              data-vv-as="Code"
              v-validate="'required|max:50'"
              :error-messages="errors.collect('code')"
              :hide-details="errors.collect('code').length > 0 ? false:true">
          </v-text-field>
          <v-text-field
              class="mt-6"
              v-model="password"
              label="Password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              outlined
              name="password"
              data-vv-as="Password"
              v-validate="'required|min:7|max:50'"
              :error-messages="errors.collect('password')"
              :hide-details="errors.collect('password').length > 0 ? false:true">
          </v-text-field>
        </div>
      </div>

      <div class="d-flex">
        <router-link to="/login" class="mt-6 d-block">
          Login
        </router-link>
        <v-spacer></v-spacer>
        <router-link to="/register" class="mt-6 d-block">
          Create an account
        </router-link> 
      </div>

      <div>
        <div> 
          <div v-if="!forgotPassword"> 
              <v-btn 
                v-if="!loading"
                class="mt-5" 
                rounded 
                color="blue" 
                dark 
                depressed 
                block
                x-large
                @click="SendCodeVerify"
                style="text-transform: capitalize;">
                <v-spacer></v-spacer>
                  Next
                <v-spacer></v-spacer>
              </v-btn>
              <v-btn 
                v-else
                class="mt-5" 
                rounded 
                color="blue" 
                dark 
                depressed 
                block
                x-large>
                <v-spacer></v-spacer>
                  <v-progress-circular
                    :width="3"
                    color="white"
                    indeterminate>
                  </v-progress-circular>
                <v-spacer></v-spacer>
              </v-btn>
          </div>
          <div v-else> 
              <v-btn 
                v-if="!loading"
                class="mt-5" 
                rounded 
                color="blue" 
                dark 
                depressed 
                block
                x-large
                @click="Reset"
                style="text-transform: capitalize;">
                <v-spacer></v-spacer>
                  Reset
                <v-spacer></v-spacer>
              </v-btn>
              <v-btn 
                v-else
                class="mt-5" 
                rounded 
                color="blue" 
                dark 
                depressed 
                block
                x-large>
                <v-spacer></v-spacer>
                  <v-progress-circular
                    :width="3"
                    color="white"
                    indeterminate>
                  </v-progress-circular>
                <v-spacer></v-spacer>
              </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Register',

    components: {
      //
    },

    data() {
      return {
        email: '',
        code: '',
        password: '',

        errMsg: '',
        sucessMsg: '',
        loading: false,
        forgotPassword: false,
        showPassword: false,
      }
    },

    methods: {
        SendCodeVerify(){
          this.$validator.validateAll().then(result => {
            if (result) {
              const app = this
              app.loading = true
              axios.post('/send-code-verify', {
                  email: this.email,
                })
                .then(response => {
                    app.loading = false
                    app.forgotPassword = true
                    app.sucessMsg = response.data.message
                    app.errMsg = ''
                }, (err) => {
                    app.loading = false
                    app.sucessMsg = ''
                    app.errMsg = err.response.data.errors.message[0]
                    // router.push({ path: '/login' })
                });
            }
          })
        },

        Reset(){
          this.$validator.validateAll().then(result => {
            if (result) {
              const app = this
              app.loading = true
              axios.post('/forgot-password', {
                  code: this.code,
                  password: this.password,
                })
                .then(response => {
                    app.loading = false
                    app.$router.push({ path: '/login' })
                }, (err) => {
                    app.loading = false
                    app.sucessMsg = ''
                    app.errMsg = err.response.data.errors.message[0]
                    // router.push({ path: '/login' })
                });
            }
          })
        },
    },
    
    computed: {
      //
    }
  }
</script>
