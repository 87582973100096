<template>
  <div>
      <v-container>
        <div class="d-flex">
          <h1 class="text-h5 font-weight-bold">Measurement Guide</h1>
          <v-spacer></v-spacer>
          <v-btn 
            small 
            target="_blank"
            rounded
            color="blue"
            depressed
            dark
            @click="dialogVideo = true">
            Tutorial
          </v-btn>
        </div>
      </v-container>
      
      <v-container>
        <v-row>
          <v-col
            class="mb-5" 
            cols="6" 
            md="6"
            v-for="guide in guides"
            :key="'guide' + guide.no"
            @click="showGuide(guide)"
            style="cursor: pointer">
            <div class="text d-flex caption">
              <div class="mr-2">{{ guide.no }}.</div>
              <div>{{ guide.text }}</div>
            </div>
            <v-img
              class="mt-1" 
              height="100"
              width="auto"
              contain 
              :src="guide.image">
            </v-img>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-btn 
          class="mt-10" 
          rounded 
          color="blue" 
          dark 
          depressed 
          block 
          to="/person"
          x-large>
          Measure
        </v-btn>
      </v-container>


    <v-dialog
        style="max-width: 500px;"
        v-model="dialogGuide"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition">
        <v-card>
            <v-app-bar
                dense 
                flat 
                color="white">
                <v-btn 
                    plain 
                    :ripple="false" 
                    icon 
                    @click="dialogGuide = false" 
                    class="mr-1 text-black black--text">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                Measurement Guide
            </v-app-bar>
            <v-card-text 
                class="mt-5 max-width-500">
                <div class="text d-flex font-weight-medium">
                    <div class="mr-2">{{ guide.no }}.</div>
                    <div>{{ guide.text }}</div>
                </div>
                <v-img
                    class="mt-1"
                    width="100%"
                    contain 
                    :src="guide.image">
                </v-img>
            </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog
        v-if="dialogVideo"
        v-model="dialogVideo"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition">
        <v-card>
        <v-app-bar
            dense 
            flat 
            color="white">
            <v-btn 
                plain 
                :ripple="false" 
                icon 
                @click="dialogVideo = false" 
                class="mr-1 text-black black--text">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            Measurement Guide
        </v-app-bar>
        <v-card-text 
            class="mt-5 max-width-500">
            <div 
                class="video" 
                :style="{ height: getWindowHeight}">
                <video 
                    width="100%" 
                    height="100%" 
                    controls="controls"
                    :poster="require('@/assets/posterVideo.png')">
                    <source 
                        :src="require('@/assets/media/fut.mp4')" 
                        type="video/mp4">
                    </video>
                </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

  export default {
    name: 'Home',

    components: {
      //
    },

    data() {
      return {
        dialogGuide: false,
        dialogVideo: false,
        guides: [
          {
            no: '1',
            text: 'Place your foot against the wall, and make sure your heel touches the wall',
            image: require('@/assets/fit-guide/1.png'),
          },
          {
            no: '2',
            text: 'Trace your foot, and make sure the pencil is at a 90-degree angle',
            image: require('@/assets/fit-guide/2.png'),
          },
          {
            no: '3',
            text: 'Measure width thickness with the cutout ruler',
            image: require('@/assets/fit-guide/3.png'),
          },
          {
            no: '4',
            text: 'Measure instep thickness with the cutout ruler',
            image: require('@/assets/fit-guide/4.png'),
          },
          {
            no: '5',
            text: 'Measure foot length, from tip of longest toe to back of heel',
            image: require('@/assets/fit-guide/5.png'),
          },
          {
            no: '6',
            text: 'Measure foot width with the cutout ruler, pick the widest point',
            image: require('@/assets/fit-guide/6.png'),
          },
          {
            no: '7',
            text: 'Measure ball girth length from heel to bunion',
            image: require('@/assets/fit-guide/7.png'),
          },
          {
            no: '8',
            text: 'Record all the values in millimeter and repeat for the other feet',
            image: require('@/assets/fit-guide/8.png'),
          },
          {
            no: '9',
            text: 'Enter millimeter values in FUT app',
            image: require('@/assets/fit-guide/9.png'),
          },
        ],

        guide: {},
        windowHeight: 0
      }
    },

    mounted() {
        this.windowHeight = window.innerHeight
    },

    methods: {
      getWindowHeight() {
        return window.innerHeight
      },

      getWindowWidth() {
        return window.innerWidth
      },

      showGuide(data){
        this.guide = data
        this.dialogGuide = true
      }
    }
  }
</script>

<style scoped>
    .max-width-500{
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
</style>