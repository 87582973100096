<template>
  <div>
      <!-- <v-container>
        <h1 class="text-h5 font-weight-bold">Summary</h1>
      </v-container> -->

      <v-container>
        <v-btn 
          class="mt-5" 
          rounded 
          color="blue" 
          dark 
          depressed 
          block
          x-large
          @click="calculate">
          Calculate
        </v-btn>
      </v-container>
      
      <v-container>
        <div>
          <h2>Personal Information</h2>
          <v-list>
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Name</v-list-item-title>
                <v-list-item-subtitle>{{ dataPersonal.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>
                  {{ dataPersonal.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0" v-if="dataPersonal.phone">
              <v-list-item-content>
                <v-list-item-title>Phone</v-list-item-title>
                <v-list-item-subtitle>
                  {{ dataPersonal.phone }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Gender</v-list-item-title>
                <v-list-item-subtitle>
                  {{ dataPersonal.gender }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Date Birth</v-list-item-title>
                <v-list-item-subtitle>
                  {{ dataPersonal.date_birth }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Height</v-list-item-title>
                <v-list-item-subtitle>
                  {{ dataPersonal.height }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <div class="mt-5">
          <h2>Measurements</h2>
          <v-list>
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Foot Length</v-list-item-title>
                <v-list-item-subtitle>
                  <div class="d-flex"> 
                    <div class="mr-5">Left</div> 
                    {{ dataMeasurement.l_foot_length }} mm
                    </div>
                    <div>
                      <div>Right</div>
                      {{ dataMeasurement.r_foot_length }} mm
                      </div>
                    <div>
                  </div>  
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Ball Girth Length</v-list-item-title>
                <v-list-item-subtitle>
                  <div class="d-flex"> 
                    <div class="mr-5">
                      
                      <div>Left</div>
                      {{ dataMeasurement.l_ball_girth_length }} mm
                    </div>
                    <div>
                      <div>Right</div>
                      {{ dataMeasurement.r_ball_girth_length }} mm
                    </div>
                  </div>  
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Foot Width</v-list-item-title>
                <v-list-item-subtitle>
                  <div class="d-flex"> 
                    <div class="mr-5">
                      <div>Left</div> 
                      {{ dataMeasurement.l_foot_width }} mm
                    </div>
                    <div>
                      <div>Right</div>
                      {{ dataMeasurement.r_foot_width }} mm
                    </div>
                  </div>  
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Width Thickness</v-list-item-title>
                <v-list-item-subtitle>
                  <div class="d-flex"> 
                    <div class="mr-5">
                      <div>Left</div> 
                      {{ dataMeasurement.l_foot_thickness }} mm
                    </div>
                    <div>
                      <div>Right</div>
                      {{ dataMeasurement.r_foot_thickness }} mm
                    </div>
                  </div>  
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Instep Thickness</v-list-item-title>
                <v-list-item-subtitle>
                  <div class="d-flex"> 
                    <div class="mr-5">
                      <div>Left</div> 
                      {{ dataMeasurement.l_instep_thickness }} mm
                    </div>
                    <div>
                      <div>Right</div>
                      {{ dataMeasurement.r_instep_thickness }} mm
                    </div>
                  </div>  
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>Socks type</v-list-item-title>
                <v-list-item-subtitle>
                  {{ dataMeasurement.socks_type }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </div>
      </v-container>
  </div>
</template>

<script>

  export default {
    name: 'Summary',

    components: {
      //
    },

    data() {
      return {
        //
      }
    },

    methods: {
      calculate(){
        this.$store.dispatch('person/save', {
            name: this.dataPersonal.name,
            gender: this.dataPersonal.gender,
            email: this.dataPersonal.email,
            date_birth: this.dataPersonal.date_birth,
            phone: this.dataPersonal.phone,
            // race: this.dataPersonal.race,
            // location: this.dataPersonal.location,
            
            // wake_up: this.dataActivity.wake_up,
            // activity_meter: this.dataActivity.activity_meter,
            // activity: this.dataActivity.activity,
            
            height: this.dataPersonal.height,
            weight: this.dataPersonal.weight,
            
            l_foot_length: this.dataMeasurement.l_foot_length,
            l_ball_girth_length: this.dataMeasurement.l_ball_girth_length,
            l_foot_width: this.dataMeasurement.l_foot_width,
            l_foot_thickness: this.dataMeasurement.l_foot_thickness,
            l_instep_thickness: this.dataMeasurement.l_instep_thickness,
            r_foot_length: this.dataMeasurement.r_foot_length,
            r_ball_girth_length: this.dataMeasurement.r_ball_girth_length,
            r_foot_width: this.dataMeasurement.r_foot_width,
            r_foot_thickness: this.dataMeasurement.r_foot_thickness,
            r_instep_thickness: this.dataMeasurement.r_instep_thickness,
            // socks_type: this.dataMeasurement.socks_type,

            id: this.dataMeasurement.id,
        })
      }
    },

    computed: {
      dataPersonal(){
        return this.$store.state.person.dataPersonal
      },
      dataHeightWeight(){
        return this.$store.state.person.dataHeightWeight
      },
      dataActivity(){
        return this.$store.state.person.dataActivity
      },
      dataMeasurement(){
        return this.$store.state.person.dataMeasurement
      },

    }
  }
</script>