<template>
  <v-container>
    <h1 class="text-h5 font-weight-bold">Profile</h1>
    
    <div>
      <div class="d-flex" v-if="user.user">
        <v-spacer></v-spacer>
          <div class="image-profile" :style="{ backgroundImage: 'url(' + user.user.image + ')' }">
              <div class="placeholder" v-if="!user.user.image">
                  Image must be jpg up to 1MB, and 160px by 160px.
              </div>
              <div class="action-btn">
                  <div v-if="!user.user.image">
                      <v-btn 
                          @click="addImage"
                          icon
                          v-if="!loadingImage">
                          <v-icon>mdi-camera</v-icon>
                      </v-btn>
                      <v-btn
                          v-else
                          icon>
                          <v-progress-circular
                              indeterminate
                              size="24">
                          </v-progress-circular>
                      </v-btn>
                  </div>
                  <div class="ml-2" v-else>
                      <v-btn 
                          v-if="!loadingImage"
                          icon
                          color="red" 
                          class="white--text"
                          @click="deleteImage(user.user.filename_image)">
                          <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn
                          v-else
                          icon>
                          <v-progress-circular
                              indeterminate
                              size="24"
                              color="red"
                              class="white--text">
                          </v-progress-circular>
                      </v-btn>
                  </div>
              </div>
              <input ref="fileInputImage" type="file" style="display: none" @input="pickFileImage">
          </div>
        <v-spacer></v-spacer>
      </div>

      <div class="mt-10">  
        <v-alert
          v-if="errMsg"
          dense
          outlined
          type="error">
          {{ errMsg }}
        </v-alert>

          <v-alert
            v-if="sucessMsg"
            dense
            outlined
            type="success">
            {{ sucessMsg }}
          </v-alert>
      </div>
      
      <div v-if="user.user" class="mt-10">
        <v-text-field
          v-model="user.user.name"
          label="Full Name"
          name="Full Name"
          data-vv-as="Full Name"
          v-validate="'required|max:50'"
          :error-messages="errors.collect('name')"
          :hide-details="errors.collect('name').length > 0 ? false:true">
        </v-text-field>

        <v-text-field
            class="mt-6"
            v-model="user.user.email"
            label="Email"
            name="email"
            data-vv-as="Email"
            v-validate="'required|email|max:50'"
            :error-messages="errors.collect('email')"
            :hide-details="errors.collect('email').length > 0 ? false:true">
        </v-text-field>

        <v-text-field
            class="mt-6"
            v-model="user.user.password"
            label="Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            name="password"
            data-vv-as="Password"
            v-validate="'min:7|max:50'"
            :error-messages="errors.collect('password')"
            :hide-details="errors.collect('password').length > 0 ? false:true">
        </v-text-field>

        <div class="mt-10">
          <v-btn 
            v-if="!loading"
            rounded 
            color="blue" 
            dark 
            depressed 
            block
            x-large
            @click="Save"
            style="text-transform: capitalize;">
            <v-spacer></v-spacer>
              Save
            <v-spacer></v-spacer>
          </v-btn>
          <v-btn 
            v-else
            rounded 
            color="blue" 
            dark 
            depressed 
            block
            x-large>
            <v-spacer></v-spacer>
              <v-progress-circular
                :width="3"
                color="white"
                indeterminate>
              </v-progress-circular>
            <v-spacer></v-spacer>
          </v-btn>
        </div>
      </div>
      
      <v-divider class="my-10"></v-divider>
      
      <div>
          <v-btn 
            class="mt-5" 
            rounded 
            color="red" 
            dark 
            depressed 
            block
            x-large
            @click="Logout"
            style="text-transform: capitalize;">
            <v-spacer></v-spacer>
              Logout
            <v-spacer></v-spacer>
          </v-btn>
        </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Register',

    components: {
      //
    },

    data() {
      return {
        showPassword: false,
        loading: false,
        loadingImage: false,

        errMsg: '',
        sucessMsg: '',
       }
    },

    created () {
      this.$store.dispatch('auth/user')
    },

    methods: {
        addImage(){
            this.$refs.fileInputImage.click()
        },
        pickFileImage(){
            const input = this.$refs.fileInputImage
            const file = input.files[0]

            const fileSize = Math.round((file.size / 1024))
            if(fileSize > 1024){
                return alert('File size exceeds 1 MiB');
            }
            
            let formData = new FormData()
            formData.append('image', file)
            formData.append('module', 'users')
            formData.append('field', 'image')
            formData.append('id', this.user.user.id)
            this.loadingImage = true
            const app = this
            axios.post('/upload-imager/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => {
              app.loadingImage = false
              app.$store.dispatch('auth/user')
            })
            .catch(err => {
                app.loadingImage = false
                app.$store.dispatch('auth/user')
            })
        },
        deleteImage(file){
            // if (confirm('Are you sure you want to delete this image?')) {
                this.loadingImage = true
                const app = this
                const data = {
                    module: 'users',
                    image: file,
                    field: 'image',
                    id: this.user.user.id
                }
                axios.post('/delete-imager/', data, {
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  }
                })
                .then(res => {
                    app.loadingImage = false
                    app.$store.dispatch('auth/user')
                })
                .catch(err => {
                    app.loadingImage = false
                    app.$store.dispatch('auth/user')
                })
            // }
        },

        Save(){
          this.$validator.validateAll().then(result => {
            if (result) {
              const app = this
              app.loading = true
              axios.post('/update-user', this.user.user, {
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  }
                })
                .then(response => {
                    app.loading = false
                    app.sucessMsg = response.data.message
                    app.errMsg = ''
                }, (err) => {
                    app.loading = false
                    app.sucessMsg = ''
                    app.errMsg = err.response.data.errors.email[0]
                });
            }
          })
        },
        
        Logout(){
          this.$validator.validateAll().then(result => {
            if (result) {
              const app = this
              app.loading = true
              axios.get('/logout', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
              })
              .then(response => {
                  localStorage.removeItem('token')
                  this.$router.push({ path: '/login' })
              }, (err) => {
                  //
              });
            }
          })
        },
    },
    
    computed: {
      user(){
        return this.$store.state.auth.user
      },
    }
  }
</script>
<style lang="scss" scoped>
    .image-profile{
        height: 160px;
        width: 160px;
        border: 2px dashed #ddd;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        .placeholder{
            font-size: 10px;
            text-align: center;
            padding: 15px
        }
        .action-btn{
            position: absolute;
            bottom: 30px;
            right: 30px;
        }    
    }
</style>